html {
  scroll-behavior: smooth;
}

a {
  color: #fff;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  bottom: 0;
  right: 0;
  z-index: 1400;
  overflow: hidden;
  margin: unset !important;
}

.white-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}
.white-text-business {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.white-text-small {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #fff;
}
.parent {
  display: flex;
}
.narrow {
  margin-top: 150px;
  padding-top: 60px;
  padding-right: 400px;
  margin-left: 100px;
  margin-right: 100px;
  padding-left: 200px;
  border-left-width: 400px;
  border-top-width: 200px;
  margin-bottom: 200px;
  padding-bottom: 100px;
  position: relative;
}
.wide {
  flex: 1;
}
.text-center {
  text-align: center;
}
.fff {
  color: #ffffff;
}

.black-text-strong {
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
}

.black-text-light {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #7a7a7a;
}

.text-italic {
  font-style: italic;
}

.black-text-14 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #55595e;
}

.black-text-11 {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 18px;
  color: #494949;
}

.black-text-small {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #494949;
}

.green-text-small {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #01ce91;
}

.clickable {
  cursor: pointer;
}

.display-grid-center {
  display: grid;
  place-items: center;
}

.lh-120 {
  line-height: 1.3 !important;
}

@media (max-width: 360px) {
  .black-text-small {
    font-size: 11px;
  }
  .black-text-14 {
    font-size: 13px;
  }
}

@media (max-width: 320px) {
  .black-text-strong {
    font-size: 15px;
  }
  .black-text-light {
    font-size: 11px;
  }
  .black-text-small {
    font-size: 9px;
  }
  .black-text-14 {
    font-size: 12px;
  }
  .black-text-11 {
    font-size: 9px;
  }
}

@media (max-width: 280px) {
  .white-text {
    font-size: 12px;
  }
  .black-text-11 {
    font-size: 8px;
  }
}

.hoverable {
  cursor: pointer;
}

@keyframes animationDropLeft {
  0% {
    max-width: 0%;
  }
  100% {
    max-width: 40%;
  }
}
