.create-link-wrapper {
  display: grid;
  grid-template-rows: auto auto auto;
  grid-row-gap: 29px;
  row-gap: 3vh;
  width: 70%;
  text-align: center;
  margin: auto;
}

.create-link-wrapper .link-wrapper {
  border-radius: 10px;
  display: grid;
  place-items: center;
  color: white;
  font-size: 1.5rem;
  padding: 1.5rem;
  cursor: pointer;
  max-width: 6rem;
  margin: 0 auto 1.5rem;
}

.create-link-wrapper .link-wrapper img {
  height: 25px;
}

.create-link-wrapper img {
  height: 62px;
}

.create-link-textbox-wrapper {
  position: relative;
}

.create-link-textbox-wrapper.username input[type='text'],
.create-link-textbox-wrapper.phone input[type='text'] {
  padding-left: 3.5rem;
}

.create-link-textbox-wrapper i.username-prefix,
.create-link-textbox-wrapper i.phone-prefix {
  display: inline-block;
  position: absolute;
  z-index: 1;
  top: 45px;
  left: 15px;
  font-weight: 500;
  font-size: 18px;
}

.create-link-textbox-wrapper i.link-prefix {
  display: inline-block;
  position: absolute;
  z-index: 1;
  top: 50px;
  left: 30px;
  font-weight: 400;
  font-size: 1.2rem;
}

.create-link-wrapper .btn {
  margin: 3vh 0;
}

.create-link-wrapper .delete-link {
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  text-align: justify;
  text-decoration-line: underline;
  color: #55595e;
}

.create-link-wrapper .delete-link img {
  width: 20px;
  height: 13px;
  padding-right: 7px;
  text-align: justify;
}

@media (max-width: 767px) {
  .create-link-wrapper {
    width: 100%;
  }
}
